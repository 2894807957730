<template>
  <v-container id="ticket-container" fluid tag="section">
    <v-row>
      <v-col col-12 col-md-8>
        <base-panel icon="mdi-ticket-account" :title="$t('tickets.edit') +
          ' - ' +
          uid +
          ' - ' +
          (project ? project.name : '')
          " v-on:drop.native="dropHandler" v-on:dragenter.native="dragEnterHandler"
          v-on:dragover.native="dragOverHandler">
          <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
            <v-card-text>
              <v-card style="border: 1px solid #c6c6ff; margin-top:-20px">
                <v-card-text class="pb-0" v-if="form.customer">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="form.customer.email" :label="$t('tickets.fields.email')"
                        prepend-icon="email" type="text" readonly></v-text-field>
                      <div v-if="adminLink" class="text-end">
                        <a :href="adminLink" :target="'admin' + project.id"><i class="icon icon-file"></i>
                          Admin-Link</a>
                      </div>
                      <v-text-field v-model="form.status" prepend-icon="mdi-ticket-confirmation"
                        :label="$t('tickets.fields.status')" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-textarea v-model="form.notice" :label="$t('projects.fields.notice')" prepend-icon="mdi-note"
                        background-color="rgba(202, 186, 139, 0.3)" rows="3" clearable clear-icon="mdi-close-circle">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="px-4 pt-0" v-if="form.customer">
                  <v-checkbox v-if="form.status != 'closed'" v-model="form.close"
                    :label="$t('tickets.close')"></v-checkbox>
                  <v-checkbox v-else v-model="form.reopen" :label="$t('tickets.reopen')"></v-checkbox>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" :disabled="!form" color="primary"><v-icon left>mdi-content-save</v-icon>
                    {{ $t("tickets.update") }}
                  </v-btn>
                </v-card-actions>
                <v-progress-linear v-if="updating && !errorMessage" color="deep-purple accent-4" indeterminate rounded
                  height="6"></v-progress-linear>
                <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
                  errorMessage
                }}</v-alert>
                <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
                  $t("tickets.saved", savedItem)
                }}</v-alert>
              </v-card>

              <v-list v-if="form.customer" id="chat_list">
                <v-list-item v-for="(item, i) in entries" :key="i" :class="getClass(item)" class="my-1" fill-dot
                  @mouseover="hover_entry = item.id" @mouseleave="hover_entry = 0">
                  <v-list-item-avatar v-if="isUser(item) && item.avatar">
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-if="item.type != 'error'" v-text="moment(item.created_at)
                      .lang($i18n.locale)
                      .format('LLL') +
                      ' - ' +
                      item.owner_name
                      "></v-list-item-title>
                    <div v-if="item.type == 'error'">
                      {{ item.message }}
                    </div>
                    <div v-else-if="isImage(item)">
                      <image-item :src="'/api/thumb/' +
                        project.id +
                        '/' +
                        item.file +
                        '/' +
                        userId +
                        '?h=' +
                        item.h
                        " :alt="item.message" :title="item.message" class="attached_img"
                        v-on:click.native="downloadAttachment(item)" />
                    </div>
                    <div v-else-if="isAttachment(item)">
                      <v-btn @click="downloadAttachment(item)" small style="text-transform:none">
                        <v-icon small>mdi-file</v-icon>
                        {{ item.message }}
                      </v-btn>
                    </div>
                    <template v-else>
                      <div v-html="item.message"></div>
                      <v-btn v-if="hover_entry == item.id" class="add-kb" color="secondary" fab dark small
                        @click="addToKb(item.message)">
                        <v-icon>mdi-tag-arrow-right</v-icon>
                      </v-btn>
                      <v-btn v-if="hover_entry == item.id && isUser(item)" class="btn-resend" color="warning" fab dark
                        small @click="resendEntry(item)">
                        <v-icon>mdi-email-sync-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-textarea v-if="form.customer" :label="$t('tickets.reply')" v-model="new_message"></v-textarea>
            </v-card-text>

            <v-card-actions v-if="form.customer">
              <v-spacer></v-spacer>
              <i id="send_file" class="v-icon mdi mdi-attachment" @click="openFileManager"></i>
              <input type="file" id="input_file" multiple="" style="display:none"
                accept="image/*,video/*,application/pdf" @change="fileInputHandler" />
              <v-btn type="button" ref="send_button" id="send_button" :disabled="!form || !new_message" color="primary"
                @click="sendMessage"><v-icon left>email</v-icon>
                {{ $t("tickets.save") }}
              </v-btn>
            </v-card-actions>
            <v-progress-linear v-if="sendingMessage" color="deep-purple accent-4" indeterminate rounded
              height="6"></v-progress-linear>
            <v-alert v-if="errorMessage2" tile class="mb-0" type="error">{{
              $t(errorMessage2)
            }}</v-alert>
          </v-form>
        </base-panel>
      </v-col>

      <v-col class="col-12 col-md-4">
        <knowledge-base ref="kb" v-if="project" :language="$i18n.locale" :project="project"
          @text="addText"></knowledge-base>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";
import moment from "moment";
import KnowledgeBase from "./KnowledgeBase.vue";
import ImageItem from "../../components/base/ImageItem.vue";

export default {
  components: { KnowledgeBase, ImageItem },
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/tickets/",
      uid: "",
      reloadBeforeEdit: true,
      defaultItem: {
        name: "",
        email: "",
        password: "",
      },
      headers: [
        {
          text: this.$t("tickets.entry.date"),
          align: "start",
          value: "created_at",
        },
        { text: this.$t("tickets.entry.message"), value: "message" },
      ],
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("tickets.fields.name"),
            }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("tickets.fields.name"),
            }),
        ],
        email: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("tickets.fields.email"),
            }),
          (v) => {
            return validate.email.test(v) || this.$t("tickets.invalid email");
          },
          () =>
            this.getInputError("email", {
              name: this.$t("tickets.fields.email"),
            }),
        ],
      },
      adminLink: '',
      new_message: "",
      errorMessage2: null,
      updating: false,
      sendingMessage: false,
      updatingEntries: false,
      entries: [],
      project: null,
      hover_entry: 0,
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("tickets.add")
        : this.$t("tickets.edit");
    },
    userId() {
      let loggedInUser = localStorage.getItem("loggedInUser");
      if (loggedInUser) {
        loggedInUser = JSON.parse(loggedInUser);
        return loggedInUser.id;
      }
      return null;
    },
    userName() {
      let loggedInUser = localStorage.getItem("loggedInUser");
      if (loggedInUser) {
        loggedInUser = JSON.parse(loggedInUser);
        return loggedInUser.first_name;
      }
      return null;
    },
  },

  created() {
    this.updating = true;
    this.uid = this.$route.params.uid;
    this.editItem({ id: this.uid }, 0);
    window.eventBus.$on("changeTicket", (ticket) => {
      if (this.uid != ticket) {
        this.updating = true;
        this.uid = ticket;
        this.editedItem = null;
        this.project = null;
        this.entries = [];
        this.errorMessage = null;
        this.serverErrors = null;
        this.updatingEntries = false;
        this.form = Object.assign({}, this.defaultItem);
        this.form.id = this.uid;
        this.editItem({ id: this.uid }, 0);
      }
    });
  },

  watch: {
    entries: (v) => {
      var sb = document.getElementById("send_button");
      if (sb) {
        sb.scrollIntoView({ behavior: "smooth" });
      }
      setTimeout(() => {
        var list = document.getElementById("chat_list");
        if (list) {
          list.scrollTop = list.scrollHeight;
        }
      }, 300);
      setTimeout(() => {
        var list = document.getElementById("chat_list");
        if (list) {
          list.scrollTop = list.scrollHeight;
        }
      }, 1000);
    },
  },

  methods: {
    moment,
    isUser(item) {
      return item.owner_type == "App\\Models\\User";
    },
    getClass(item) {
      let t = item.owner_type == "App\\Models\\User" ? "user" : "customer";
      if (item.type == "image") {
        t += " fit";
      }
      if (item.type == "error") {
        t += " error";
      }
      return t;
    },
    saveItem() {
      this.errorMessage = null;
      this.serverErrors = null;
      this.updating = true;
      this.patch(this.apiQuery + this.form.id, this.form)
        .then((res) => {
          this.form = res;
          this.entries = this.form.entries;
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
    sendMessage() {
      this.errorMessage2 = null;
      this.sendingMessage = true;
      this.patch(this.apiQuery + this.form.id, { message: this.new_message })
        .then((res) => {
          this.form = res;
          this.entries = this.form.entries;
          this.sendingMessage = false;
          this.new_message = "";
        })
        .catch((error) => {
          this.sendingMessage = false;
          this.errorMessage2 = error.message || error.error;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
    onDataLoaded() {
      if (!this.project) {
        this.project = this.form.project;
        setTimeout(() => {
          var list = this.$el.querySelector("#chat_list");
          if (list) {
            list.scrollTop = list.scrollHeight;
          }
        }, 100);
      }
      if (!this.updatingEntries) {
        this.entries = this.form.entries;
        this.updating = false;
        if (this.$route.params.uid == this.uid) {
          setTimeout(() => {
            this.updateEntries(this.form.tstamp);
          }, 3000);
        }
      }
      if (this.project.admin_url) {
        this.adminLink = this.project.admin_url + this.form.customer.email;
      }
    },
    updateEntries(since) {
      if (this.updatingEntries) {
        return;
      }
      this.updatingEntries = true;
      //console.dir("UPDATE " + new Date());
      this.get(this.apiQuery + this.form.id + "?since=" + since)
        .then((item) => {
          if (item.ticket_id != this.form.id) {
            this.updatingEntries = false;
            return;
          }
          loop1: for (var en in item.entries) {
            for (var old in this.entries) {
              if (this.entries[old].id == item.entries[en].id) {
                continue loop1;
              }
            }
            this.entries.push(item.entries[en]);
          }
          this.form.tstamp = item.tstamp;
          this.updatingEntries = false;
          this.onDataLoaded();
        })
        .catch((error) => {
          this.updating = false;
          this.updatingEntries = false;
          this.$emit("serverError", error);
        });
    },
    addText(text) {
      if (this.new_message.length) {
        this.new_message += "\n" + text;
      } else {
        this.new_message += text;
      }
    },
    addToKb(text) {
      this.$refs.kb.addToKb(text);
    },
    isImage(item) {
      return item.type == "image" && item.file;
    },
    isAttachment(item) {
      return item.type == "attachment" && item.file;
    },
    downloadAttachment(item) {
      window.open(
        "/api/dl/" +
        this.project.id +
        "/" +
        item.file +
        "/" +
        this.userId +
        "?h=" +
        item.h,
        "_blank"
      );
    },
    resendEntry(item) {
      this.post("/entry/" + item.id + "/resend/", {}).catch((error) => {
        alert(error.message);
      });
    },
    sendFile(file) {
      if (file.size > 10485760) {
        //10 MB
        this.entries.push({
          type: "error",
          message: file.name + " " + this.$t("tickets.max filesize"),
          owner_type: "App\\Models\\User",
        });
        return;
      }
      let ok =
        file.type.includes("image/") ||
        file.type.includes("video/") ||
        file.type.includes("/pdf");
      if (!ok) {
        this.entries.push({
          type: "error",
          message: file.name + " " + this.$t("tickets.file type not allowed"),
          owner_type: "App\\Models\\User",
        });
        return;
      }
      let entry = {
        type: "attachment",
        message: file.name,
        file: file.name,
        created_at: new Date(),
        owner_name: this.userName,
        uploading: true,
        owner_type: "App\\Models\\User",
      };
      this.entries.push(entry);
      var data = new FormData();
      data.append("file", file);
      this.upload(this.apiQuery + this.form.id + "/upload", data)
        .then((res) => {
          entry.h = res.h;
          entry.file = res.file;
          entry.type = res.type;
          entry.uploading = false;
          entry.id = res.id;
          setTimeout(() => {
            var list = document.getElementById("chat_list");
            if (list) {
              list.scrollTop = list.scrollHeight;
            }
          }, 100);
        })
        .catch((err) => {
          entry.type = "error";
          entry.message += " - " + this.$t("tickets." + err.message);
        });
    },
    dropHandler(ev) {
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        if (ev.dataTransfer.items.length > 10) {
          this.errorMessage2 = this.$t("tickets.max 10 files");
          return;
        }
        [...ev.dataTransfer.items].forEach((item, i) => {
          if (item.kind === "file") {
            const file = item.getAsFile();
            this.sendFile(file);
          }
        });
      } else {
        if (ev.dataTransfer.files.length > 10) {
          this.errorMessage2 = this.$t("tickets.max 10 files");
          return;
        }
        [...ev.dataTransfer.files].forEach((file, i) => {
          this.sendFile(file);
        });
      }

      if (ev.dataTransfer.items) {
        ev.dataTransfer.items.clear();
      }
    },
    dragEnterHandler: function (e) {
      e.preventDefault();
      e.stopPropagation();
    },

    dragOverHandler: function (e) {
      e.preventDefault();
      e.stopPropagation();
    },

    openFileManager: function () {
      var input = this.$el.querySelector("#input_file");
      input.click();
    },

    fileInputHandler: function (ev) {
      var files = ev.target.files;
      if (files.length > 10) {
        this.errorMessage2 = this.$t("tickets.max 10 files");
        return;
      }
      for (let file of files) {
        this.sendFile(file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  display: grid;

  .v-list-item {
    border-radius: 4px;
    padding: 0 15px;

    .v-list-item__title {
      border-bottom: 1px solid rgb(59, 55, 55);
      margin-top: -5px;
      margin-bottom: 5px;
    }
  }

  .v-list-item.user {
    background-image: linear-gradient(rgba(24, 104, 104, 0.3),
        rgba(10, 59, 59, 0.4));
    margin-left: 50px;
    justify-self: right;
  }

  .v-list-item.customer {
    margin-right: 50px;
    justify-self: left;
    background-image: linear-gradient(rgba(117, 102, 34, 0.3),
        rgba(75, 63, 10, 0.4));
  }
}

.add-kb {
  position: absolute;
  right: 0;
  bottom: 5px;
}

.btn-resend {
  position: absolute;
  right: 45px;
  bottom: 5px;
}

.attached_img {
  max-height: 300px;
  max-width: 300px;
  cursor: pointer;
}

.fit {
  width: min-content;
}

#chat_list {
  max-height: 50vh;
  overflow-y: auto;
}

#send_file {
  color: gray;
  margin-right: 10px;
  transform: rotate(90deg);
}

#send_file:hover {
  cursor: pointer;
  color: black;
}
</style>

<style lang="scss">
.v-text-field .v-input__append-inner {
  display: none !important;
}
</style>
